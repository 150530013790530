import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleAttachment = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section column is-10 is-offset-1 content">
          <div className="container">
            <div className="content">
              <h1>Job application</h1>
              <form
                name="job-application"
                method="post"
                action="/jobs/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="file-upload" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="columns">
                    <div className="column is-6">
                        <div className="field">
                            <label className="label" htmlFor={'name'}>
                            Your name*
                            </label>
                            <div className="control">
                            <input
                                className="input"
                                type={'text'}
                                name={'name'}
                                onChange={this.handleChange}
                                id={'name'}
                                required={true}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'email'}>
                            Your email Email address*
                            </label>
                            <div className="control">
                            <input
                                className="input"
                                type={'email'}
                                name={'email'}
                                onChange={this.handleChange}
                                id={'email'}
                                required={true}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'website'}>
                            Your Phone number*
                            </label>
                            <div className="control">
                            <input
                                className="input"
                                type={'text'}
                                name={'website'}
                                onChange={this.handleChange}
                                id={'website'}
                                required={true}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'linkedin'}>
                            Linkedin
                            </label>
                            <div className="control">
                            <input
                                className="input"
                                type={'text'}
                                name={'linkedin'}
                                onChange={this.handleChange}
                                id={'linkedin'}
                                required={false}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'github'}>
                            Github
                            </label>
                            <div className="control">
                            <input
                                className="input"
                                type={'text'}
                                name={'github'}
                                onChange={this.handleChange}
                                id={'github'}
                                required={false}
                            />
                            </div>
                        </div>
                    </div>
                    <div className="column is-6">
                        <div className="field">
                            <label className="label" htmlFor={'job'}>
                            Which job are you applying for?
                            </label>
                            <div className="select">
                                <select
                                    className="select"
                                    type="radio"
                                    name={'job'}
                                    onChange={this.handleChange}
                                    id={'job'}
                                    required={false}                
                                >
                                    <option value="Senior Lead Developer">Senior Lead Developer</option>
                                    <option value="Front End Lead">Front End Lead</option>
                                    <option value="small">General Application</option>
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label" htmlFor={'message'}>
                            Tell us a bit about yourself
                            </label>
                            <div className="control">
                            <textarea
                                className="textarea"
                                name={'message'}
                                onChange={this.handleChange}
                                id={'message'}
                                required={true}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <div className="file button">
                                <label className="file-label">
                                <input
                                    className="file-input"
                                    type="file"
                                    name="attachment"
                                    onChange={this.handleAttachment}
                                />
                                <span className="file-cta">
                                    <span className="file-label">Attach a CV…</span>
                                </span>
                                </label>
                                </div>
                            </div>
                            <div className="field">
                                <button className="button is-primary" type="submit">
                                    Submit
                                </button>
                                </div>
                        </div>
                    </div>
               
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}